import React, { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { isMobile } from 'react-device-detect';
import jwt_decode from "jwt-decode";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const Header = ({ setIsLogout }) => {  
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    try {
      setUserInfo(jwt_decode(localStorage.getItem('token')));
    } catch (error) {
      console.log(error)
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    setIsLogout(false);
    window.location.href = "/";
  };

  const toggleSidebar = () => {
    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");
  };

  return (
    <>
      <Navbar className="header px-2" bg="" expand="lg" variant="" style={{ marginBottom: isMobile ? "1rem" : "0" }}>
        <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={toggleSidebar}>
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="/"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="">
          <Nav className="me-auto w-50 mt-1 ps-0 ms-0">
            <Nav.Link to="/" className="d-flex p-0 ps-0" style={{ alignItems: "center" }}> <Badge bg="primary mx-2" style={{ fontSize: ".9rem", backgroundColor  : "var(--primary-color) !important" }} >Jajot Enterprises</Badge> </Nav.Link>
          </Nav>

          <div className="ml-auto d-flex align-items-center mx-1 float-end mt-0">
            {/* <i className="fa-solid fa-bell cic mx-2" style={{ cursor: "pointer", fontSize: "1.5rem" }}></i> */}

            {localStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (
              <></>
            )}

          </div>
        </Navbar.Collapse>
      </Navbar>

    </>
  );
};

export default Header;
