import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import JajotApi from "../../api/JajotApi";
import toast, { Toaster } from 'react-hot-toast';
import Heading from "../common/Heading";
import Shimmer from "../common/Shimmer";

const ChangePassword = ({ }) => {
    const [updatedSuccess, setUpdateSuccess] = useState(false);
    const [isSpinner, setIsSpinner] = useState();
    const [validated, setValidated] = useState(false);
    const [newPass, setNewPass] = useState(false);
    const [conPas, setConPass] = useState(false);
    const [user, setUser] = useState({ password: '', confirmpassword: '' });

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setUser({ password: "", confirmpassword: "" });
        setValidated(undefined)
    }, [updatedSuccess])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user?.password?.length > 3 && user?.password?.length < 11) {
            if (user?.password === user?.confirmpassword) {
                user.authToken = localStorage.getItem('token');
                setValidated(true);
                let result = await JajotApi.updateUserPassword(user);
                if (result?.message === "Record updated successfully") {
                    setIsSpinner(true)
                    setTimeout(() => {
                        setIsSpinner(false)
                    }, 1000)
                    setUpdateSuccess(true);
                    toast.success("Password updated successfully")
                }
            } else {
                setValidated(false);
                toast.error("New pasword and confirm password are not matched")
            }
        } else {
            toast.error("Password length must be between 4 to 10 characters")
        }
    };

    return (
        <>
            <Heading headingName={"Change Password"} />

            {
                !isSpinner ?
                    <Container className="view-form mt-3">
                        <Row className="d-flex justify-content-center align-items-center">
                            <Col lg={10}>
                                <Form onSubmit={handleSubmit} validated={validated}>
                                    <Row className="pb-3 mx-1">
                                        <Col lg={12}>
                                            <Form.Group className="mx-3">
                                                <Form.Label className="form-view-label" htmlFor="formAddress" style={{ fontSize: "15px" }}>
                                                    New Password
                                                    <i className="fa-solid fa-circle-info mx-2" style={{ cursor: "pointer" }} title="Minimum Characters: 4, Maximum Characters: 10"></i>
                                                </Form.Label>
                                                <Form.Control type={newPass ? "text" : "password"} name="password" value={user.password} placeholder="Enter Your password" onChange={handleChange} required />
                                                {
                                                    !validated &&
                                                    <i className={`${newPass === false ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"}`}
                                                        onClick={() => { setNewPass(!newPass) }} title={`${newPass === false ? "Show" : "Hide"}`}
                                                        style={{ left: "90%", position: 'relative', zIndex: '999', cursor: "pointer", bottom: "22px" }}
                                                    ></i>
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="mx-3 ">
                                                <Form.Label className="form-view-label" style={{ fontSize: "15px" }}>
                                                    Confirm Password
                                                    <i className="fa-solid fa-circle-info mx-2" style={{ cursor: "pointer" }} title="Minimum Characters: 4, Maximum Characters: 10"></i>
                                                </Form.Label>
                                                <Form.Control type={conPas ? "text" : "password"} name="confirmpassword" value={user.confirmpassword} placeholder="Enter confirm password" onChange={handleChange} required />
                                                {
                                                    !validated &&
                                                    <i className={`${conPas === false ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"}`}
                                                        onClick={() => { setConPass(!conPas) }} title={`${conPas === false ? "Show" : "Hide"}`}
                                                        style={{ left: "90%", position: 'relative', zIndex: '999', cursor: "pointer", bottom: "22px" }}
                                                    ></i>
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Col lg={12} className="d-flex justify-content-end me-2">
                                        <Button type="submit" className="btn btn-outline-success">Update</Button>
                                    </Col>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    : isSpinner && <Shimmer />
            }
            <Toaster />
        </>
    );
};

export default ChangePassword;
