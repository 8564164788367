import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const LeaveDashBoard = ({ dashBoardDetail }) => {
    return (
        <Container>
            <Row className="d-flex justify-content-center align-items-center">
                <Col lg={10}>
                    <Row>
                        <Col lg={3} md={6} sm={12}>
                            <div className='item'>
                                <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(154 235 233)", borderRadius: "5px", }} >
                                    <span className="fa-stack fa-2x">
                                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(154 235 233)" }} ></i>
                                        <i className="fa-thin fa-check fa-solid fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                    </span>
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 d-inline mx-3">{dashBoardDetail?.approvedLeaves ? dashBoardDetail?.approvedLeaves : "0"}</h4>
                                        <h6 className="text-muted mb-1 heading" style={{ color: "#C9F6F5" }} >Approved</h6>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} md={6} sm={12}>
                            <div className='item'>
                                <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid #e0c5f6", borderRadius: "5px", }} >
                                    <span className="fa-stack fa-2x">
                                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "#e0c5f6" }} ></i>
                                        <i className="fa-thin fa-check fa-solid fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                    </span>
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 d-inline mx-3">{dashBoardDetail?.rejectedLeaves ? dashBoardDetail?.rejectedLeaves : '0'}</h4>
                                        <h6 className="text-muted mb-1 heading" style={{ color: "#C9F6F5" }} >Rejected</h6>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} md={6} sm={12}>
                            <div className='item'>
                                <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(207 203 214)", borderRadius: "5px", }} >
                                    <span className="fa-stack fa-2x">
                                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(207 203 214)" }} ></i>
                                        <i className="fa-solid fa-hourglass-end fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                    </span>

                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 d-inline mx-3">{dashBoardDetail?.usedCL ? dashBoardDetail?.usedCL : "0"}</h4>
                                        <h6 className="text-muted mb-1 heading" style={{ color: "#605C68" }} >Used CL</h6>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} md={6} sm={12}>
                            <div className='item'>
                                <div className="p-2 d-flex align-items-center mt-3" style={{ backgroundColor: "white", borderLeft: "5px solid rgb(199 216 241)", borderRadius: "5px", }} >
                                    <span className="fa-stack fa-2x">
                                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: "rgb(199 216 241)" }} ></i>
                                        <i className="fa-solid fa-money-bill-1 fa-stack-1x" style={{ color: "#281e1ea3", fontSize: "2rem" }}></i>
                                    </span>

                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 d-inline mx-3">{dashBoardDetail?.lossOfPay ? dashBoardDetail?.lossOfPay : "0"}</h4>
                                        <h6 className="text-muted mb-1 heading" style={{ color: "#605C68" }} >Loss of Pay</h6>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default LeaveDashBoard