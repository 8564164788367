import { BrowserRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Toaster } from 'react-hot-toast';

import Main from "./components/layout/Main";
import Home from "./components/Home";
import Leave from "./components/leave";
import Attendance from "./components/attendance";
import Payroll from "./components/payrole";
import Login from "./components/Login";
import ChangePassword from "./components/changepassword"
import PayrollView from "./components/payrole/PayrollView";
import Target from "./components/target";

import "./App.css";
import "./Sidebar.css"
import "bootstrap/dist/css/bootstrap.min.css";
import Error from "./components/common/Error";


function App() {
  const [isLogout, setIsLogout] = useState(false);

  useEffect(() => {
  }, [isLogout])

  return (
    <>
      <Toaster position='top-center' />

      <Router>
        <Routes>

          <Route path="/" element={localStorage.getItem('token') ? <Main setIsLogout={setIsLogout} /> : <Login setIsLogout={setIsLogout} />} >
            <Route index element={<Home />} />
            <Route path="attendance" element={<Attendance />} />
            <Route path="payroll" element={<Outlet />} >
              <Route index element={<Payroll />} />
              <Route path="view/:id" element={<PayrollView />} />
            </Route>
            <Route path="leave" element={<Leave />} />
            <Route path='target' element={<Target />} />
            <Route path="changepassword" element={<ChangePassword />} />
          </Route>

          <Route path="/*" element={<Error />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
