import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { Col, Row } from "react-bootstrap";

import "react-calendar/dist/Calendar.css";
import JajotApi from "../../api/JajotApi";

const HolidaysCalender = () => {
    const [holidayDates, setHolidayDates] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); 

    useEffect(() => {        
        fetchHolidayRecords();
    }, []);

    const fetchHolidayRecords = async () => {
        try {
            const response = await JajotApi.getHolidays();            
            if (response.success) {
                const newHolidays = response?.records.map(holiday => {
                    let newName = holiday.Name;                   
                    return {
                        ...holiday,
                        Name: newName
                    };
                });
                setHolidayDates(newHolidays);
            }
        } catch (error) {
            console.error("Error fetching holiday records:", error);
        } finally {
            // setLoading(false);
        }
    }
    
    const tileClassName = ({ date }) => {        
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });
        var formattedDate = year + "-" + month + "-" + day;
        const isHoliday = holidayDates.some((holidayDate) => formattedDate === holidayDate?.ActivityDate);        
        const isCurrentDate = date.toISOString().split("T")[0] === currentDate.toISOString().split("T")[0];        

        return isHoliday ? "highlighted-blue" : isCurrentDate ? "highlighted-current-date" : "";
    };

    const holidaysByMonth = {};

    holidayDates.forEach(item => {
        const activityDate = new Date(item?.ActivityDate);
        const month = activityDate.getMonth(); 
               
        if (!holidaysByMonth[month]) {
            holidaysByMonth[month] = [];
        }
        holidaysByMonth[month].push(item);
    });

    const showNextMonth = () => {
        setCurrentMonth((prevMonth) => (prevMonth + 1) % 12);
    };

    const showPreviousMonth = () => {
        setCurrentMonth((prevMonth) => (prevMonth - 1 + 12) % 12);
    };

    return (
        <>
            <Row className="d-flex justify-content-center p-0 m-0 border-0"> 
                <Calendar tileClassName={tileClassName} value={currentDate} />
            </Row>
        </>
    );
}
export default HolidaysCalender;