import * as constants from '../constants/CONSTANT';
import authApi from "./authApi";

const JajotApi = {
  // ************************************** IBS-JAJOT-APIS ************************************************

  // *********************************Access Salesforce Contact Record according to email id ****************************
  async getContactRecordByEmail(email) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/useremail/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // ************************** Access All the holiday dates *****************************
  async getHolidays() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/holidays/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  //************************ */ Access All the project list *************************
  async getProjectRecords() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/projects", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // ***************************Access All the working hours according to start and end date **************
  async getWorkingHours(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/workinghours/" + '?startDate=' + data.startDate + '&endDate=' + data.endDate, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // ********************************* Create New timesheet working hour project record ************************
  async createProjectRecords(records) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/workinghours",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(records),
      });
    return await response.json();
  },  
  async sendEmailOTPVerification(request) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/request_otp",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(request),
      });
    return await response.json();
  },

  // ***********************************************************

  async getTimesheetRecordsMonth(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/timesheetmonthdata/" + '?startDate=' + data.startDate + '&endDate=' + data.endDate, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },
  async otpverification(request) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/otpverify",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(request),
      });
    return await response.json();
  }, 

  // ***************** Attendane Section ***********************


  // Created by anirudh (Attendance section Api's)
  async saveAttendance(attendanceDetails, empId) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/attendance/" + empId,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(attendanceDetails),
      }
    );
    return await response.json();    
  },

  // Retrive the attendance record as the employeeid and only the today's attendance record if it is filled.
  async getAllAttendance(employeeId) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/attendance/" + employeeId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        }
      }
    );
    return await response.json();
  },

  // Retrive the payslip records financial year and list of display on payroll section.
  async getPaySlipRecords() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/pay_slip", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },



  // Retrive the employees from contact SF object
  async getEmployeeRecords() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/employee", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json", Authorization: token },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // Update the employee on the salesforce
  async updateEmployeeRecords(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/employee",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(data),
      });
    return await response.json();
  },




  // Update the user password by change password section
  async updateUserPassword(user) {
    let response = await fetch(constants.API_BASE_URL + "/api/auth/updatepassword",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": user.authToken,
        },
        body: JSON.stringify(user),
      }
    );
    return await response.json();
  },

  // Leave DashboardView records
  async getLeavesDashboardRecord() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leaves/counts", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async getAllLeavesRecords(obj) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leaves/apply?startDate=" + obj.startDate + '&endDate=' + obj.endDate, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },


  // Retriving all the user for notify team members
  async getAllUsers() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leaves/members", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json", Authorization: token },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async getLeavesTableData() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leaves", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // Insert leave records
  async createLeavesRecords(records) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leaves/apply",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(records),
      });
    return await response.json();
  },

  // Insert or Update the employee image in attendance section 
  async updateEmployeeProfileImage(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/employee/image",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(data),
      });
    return await response.json();
  },

  // Get attendance image 
  async getAttendanceImg() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/attendanceimage",
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
    return await response.json();
  },

  // Get sidebar image for profile
  async getEmployeeImage() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/employee/image/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async getPaySlipViewRecord(monthSlab) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/pay_slip_view/" + monthSlab, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
  },

  async getEmployeeImages() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/employee/images/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  // Retrive the Pdf for a particular payroll by its ID
  async getPayrollPDF(payrollId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/payrollattachement/" + payrollId, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },


  // Save Target amount
  async saveTargetAchieveAmount(dataToSave, recordId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/target/" + recordId, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(dataToSave)
    });
    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result) {
      return result;
    }
    return null;
  },

  async getTotalTargetAmount(record, month, year) {
    let recordId = record?.contactDetails?.Id
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/target/" + recordId, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ month, year })
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async getRemainingAndTarget(recordId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/target/" + recordId, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async social_login(google_profile, token) {
    const { email, name, picture, sub } = google_profile;    
    let response = await fetch(constants.API_BASE_URL + "/api/auth/social_login", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        email: email,
        name: name,
        picture: picture.data ? picture.data.url : picture,
        sub: sub,
        password: token
      }),
    });

    const result = await response.json();    
    if (result.success) {
      localStorage.setItem("token", result.authToken);

    }
    return result;
  },

  async changeLeaveStatusToRevoke(leaveRecord, recordId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leaves/changestatus/" + recordId, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(leaveRecord)
    });

    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async getHolidays() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/salesforce/holidays/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },
}
export default JajotApi