import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

const Achievement = ({ givenTarget, achievedAmount }) => {
    const formatNumberWithDecimals = (number) => {
        const formattedNumber = number?.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return ` ${formattedNumber}`;
    }
    return (
        <Container className='d-flex justify-content-center' >
            <Row className='d-flex w-100 justify-content-center align-items-center mt-3'>
                <Col lg={10} md={12} sm={12} >
                    <Row className='d-flex justify-content-around gap-2'>
                        <Col lg={5} md={12} sm={12} >
                            <Card style={{ borderLeft: "5px solid red !important", backgroundColor: "#feeae4", fontSize : "20px !important" }}> 
                                <Card.Body className='d-flex justify-content-center  align-items-center'>
                                    <i className="fa-solid fa-bullseye mx-2 "></i> <span className='fw-bold '>Target : &#8377; {formatNumberWithDecimals(givenTarget)}</span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={5} md={12} sm={12}  >
                            <Card style={{ backgroundColor: "#9aebe9" }}>
                                <Card.Body className='d-flex justify-content-center align-items-center'>
                                    <i className="fa-regular fa-thumbs-up mx-2 "></i> <span className=' fw-bold'>Achived : &#8377; {formatNumberWithDecimals(achievedAmount)}</span>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Achievement