import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable'

const LeaveDataTable = ({ header, body, fetchLeaveTableRecords, setBody }) => {
    const [selectedYearMonth, setSelectedYearMonth] = useState({ year: new Date().getFullYear() });

    const onFilterByMonth = async (e) => {
        let filteredBody = new Array();
        let tempYearMonth = { month: e.target.value, year: selectedYearMonth.year };
        let result = await fetchLeaveTableRecords();
        if(e.target.value !== ''){
            result.map((item) => {
                let monthNumber = moment(item.leaveDate).format("MM")
                let yearNumber = moment(item.leaveDate).format("YYYY");
                if ((tempYearMonth.month === monthNumber) && tempYearMonth.year === Number(yearNumber)) {
                    filteredBody.push(item);
                }
            })
            setBody(filteredBody);
        }else{
            setBody(result);
        }
    }

    const filterByYearAndMonth = (yearAndMonth) => {
        let filteredBody = new Array();
        body.map((item) => {
            let monthNumber = moment(item.leaveDate).format("MM");
            let yearNumber = moment(item.leaveDate).format("YYYY");
            if ((yearAndMonth.month === monthNumber) && yearAndMonth.year === Number(yearNumber)) {
                filteredBody.push(item);
            }
        })
        setBody(filteredBody)
    }

    useEffect(() => {
        setSelectedYearMonth({ year: Number(moment(body[0]?.leaveDate).format("YYYY")), month: moment(body[0]?.leaveDate).format("MM") })
    }, []);

    const onFilterByYear = (e) => {
        setSelectedYearMonth({ ...selectedYearMonth, year: Number(e.target.value) })
    }
    
    const labels = { beforeSelect: " " }

    return (
        <Container className='mb-5 d-flex justify-content-center' style={{ paddingBottom: "60px" }}>
            <Row className='w-100 d-flex justify-content-center' >
                <Col lg={10} sm={12} xs={12} className='ps-0 pe-0'>
                    <DatatableWrapper headers={header} body={body} paginationOptionsProps={{ initialState: { rowsPerPage: 10, options: [5, 10, 15, 20] } }} >
                        <Row className="mb-2 justify-content-end">
                            <Col lg={4} xs={12} sm={12} className="w-75">
                                <Row className='justify-content-end'>
                                    <Col lg={4} xs={12} sm={12} style={{ width: "fit-content" }}>
                                        <Form.Group className='mb-3'>
                                            <Form.Select aria-label="Enter status" name="month" value={selectedYearMonth?.month} onChange={onFilterByMonth}
                                            >
                                                <option value="">Month</option>
                                                <option value="01">January</option>
                                                <option value="02">February</option>
                                                <option value="03">March</option>
                                                <option value="04">April</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">August</option>
                                                <option value="09">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} xs={12} sm={12} style={{ width: "fit-content" }}>
                                        <Form.Group className='mb-3' >
                                            <Form.Select aria-label="Enter year" name="year" value={selectedYearMonth?.year} onChange={onFilterByYear}
                                            >
                                                <option value="">Year</option>
                                                <option value="2025">2025</option>
                                                <option value="2024">2024</option>
                                                <option value="2023">2023</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={3} xs={12} sm={12} className='mb-3 collector'  >
                                <Filter placeholder='Search...' />
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col lg={12} sm={12} xs={12} className='table-min-div d-flex justify-content-center'>
                                <Table striped className="data-table">
                                    <TableHeader />
                                    <TableBody />
                                </Table>
                            </Col>
                        </Row>

                        <Row className="align-items-center justify-content-end ">
                            <Col lg={12} xs={12} sm={12} className="d-flex align-items-center justify-content-end">
                                <span className='me-2 mt-3'>
                                    Show
                                </span>
                                <PaginationOptions labels={labels} />
                                <div className='mt-3 mx-2'>
                                    Records
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-4 align-items-center justify-content-end">
                            <Col lg={12} xs={12} sm={12}>
                                <div className='text-end mt-3'>
                                    <Pagination />
                                </div>
                            </Col>
                        </Row>
                    </DatatableWrapper>
                </Col>
            </Row>
        </Container>
    )
}

export default LeaveDataTable