import React, { useEffect, useState } from 'react'
import { Accordion, Alert, Badge, Card, Col, Container, Form, ListGroup, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import Shimmer from '../common/Shimmer';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import toast, { Toaster } from 'react-hot-toast';
import Heading from '../common/Heading';
import Achievement from './Achievement';
import JajotApi from '../../api/JajotApi';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from 'react-bootstrap/ProgressBar';


const monthList = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" }
]

const yearList = [
    { label: "2024", value: 2024 },
    { label: "2025", value: 2025 },
    { label: "2026", value: 2026 },
    { label: "2027", value: 2027 },
    { label: "2028", value: 2028 },
    { label: "2029", value: 2029 },
    { label: "2030", value: 2030 },
]

const Target = () => {
    const defaultYear = new Date().getFullYear().toString()
    const defaultMonth = moment().month(new Date().getMonth()).format("MMMM")
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const [activatePage, setActivatePage] = useState(false);
    const [initalTarget, setInitialTarget] = useState();
    const [selectedMonth, setSlectedMonth] = useState();
    const [selectedYear, setSeletecYear] = useState();
    const [daysInMonth, setDaysInMonth] = useState(moment().month());
    const [enableCustomShimmer, setEnableCustomShimmer] = useState(undefined);
    const [isSpinner, setIsSpinner] = useState();

    function getAllDatesOfMonth(date) {
        const mDate = moment(date, "YYYY-MM");
        const daysCount = mDate.daysInMonth();
        return Array(daysCount).fill(null).map((v, index) => {
            const addDays = index === 0 ? 0 : 1;
            return { date: mDate.add(addDays, 'days').format('YYYY-MM-DD'), amount: "" };
        });
    }

    const formatNumberWithDecimals = (number) => {
        const formattedNumber = number?.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return ` ${formattedNumber}`;
    }

    const weekOfMonth = (date) => {
        if (selectedMonth.label === moment(new Date()).format("MMMM")) {
            let weekInYearIndex = date.week();
            if (date.year() !== date.weekYear()) {
                weekInYearIndex = date.clone().subtract(1, 'week').week() + 1;
            }
            const weekIndex = weekInYearIndex - moment(date).startOf('month').week() + 1;
            return weekIndex
        }
        return 1;
    }

    let setDaysAccordingToMonth = async (selected) => {
        let date = (selectedYear?.value == undefined ? defaultYear : selectedYear.value) + "-" + selected.value + "-" + "01"

        setIsSpinner(false);
        let weekWithDaycollection = getAllDatesOfMonth(date);

        let result = await JajotApi.getTotalTargetAmount(jwtDecode(localStorage.getItem('token')), selected.label, selectedYear?.value == undefined ? Number(defaultYear) : selectedYear.value);
        
        if (result.success) {
            setIsSpinner(true);

            let collection = result.records?.achivedTargetDetails;            
            let tempDatesCollection = []
            if (collection) {
                collection = collection?.map((item) => ({ date: item?.date, amount: item?.achieveamount }))
                weekWithDaycollection.map((item) => {
                    let data = collection?.filter((subItem) => subItem.date === item.date && tempDatesCollection.push(subItem))
                    if (data?.length === 0) {
                        tempDatesCollection.push(item)
                    }
                })

            } else {
                tempDatesCollection = weekWithDaycollection;
            }

            weekWithDaycollection = tempDatesCollection;

            // Array conversion into chunks
            let [converstion, chunkSize] = [weekWithDaycollection, 7];
            converstion = [...Array(Math.ceil(converstion.length / chunkSize))].map(_ => converstion.splice(0, chunkSize))

            setEnableCustomShimmer(true);
            setDaysInMonth(converstion)
            setInitialTarget(result?.records)
        } else {
            setIsSpinner(true);
            setEnableCustomShimmer(false)
            setDaysInMonth([]);
            setInitialTarget()
        }

    }

    const handleSubmitDetails = async () => {
        let userInfo = jwtDecode(localStorage.getItem('token'))
        let submittionObject = new Object();
        if (typeof (selectedYear) == 'string') {
            let year = { label: selectedYear, value: Number(selectedYear) }
            Object.assign(submittionObject, { employeeId: userInfo.contactDetails.Id, month: defaultMonth, year: year.value })
        } else {
            Object.assign(submittionObject, { employeeId: userInfo.contactDetails.Id, month: selectedMonth.label, year: selectedYear.value })
        }
        Object.assign(submittionObject, { subCollection: [] });
        daysInMonth.map((item) => item.map((subItem) => submittionObject.subCollection.push({ amount: Number(subItem.amount), submittedDate: subItem.date })))
        setIsSpinner(false);
        let saveRecordResult = await JajotApi.saveTargetAchieveAmount(submittionObject, userInfo.contactDetails.Id)
        if (saveRecordResult.success) {
            setIsSpinner(true);
            toast.success(saveRecordResult.message);
            setActivatePage(!activatePage);
        }
    }

    const addAmountToSpecificRecord = (e, index, subIndex) => {
        if (daysInMonth[index][subIndex].amount !== '') {
            daysInMonth[index][subIndex].amount = '';
            setDaysInMonth([...daysInMonth])
        }
        if (!isNaN(e.target.value)) {
            daysInMonth[index][subIndex].amount = formatNumberWithDecimals(e.target.value);
            setDaysInMonth([...daysInMonth])
        }
    }

    useEffect(() => {
        setSeletecYear({ label: defaultYear, value: Number(defaultYear) });
        setSlectedMonth({ label: defaultMonth, value: moment().month(defaultMonth).format("MM") });
        setDaysAccordingToMonth({ value: moment().month(defaultMonth).format("MM"), label: defaultMonth });
    }, [activatePage])

    return (
        <>

            <Heading headingName={"Target"} />

            <Container className="mt-3">
                <Form className='w-100'>
                    <Row className='d-flex mx-2 justify-content-center align-items-center'>
                        <Col lg={10} sm={12}>
                            <Row className='d-flex justify-content-between' style={{ zIndex: "999 !important" }}>
                                <Col sm={6} lg={4} md={5} className='z-index'>
                                    <Form.Group className='my-select' >
                                        <Form.Label className="form-view-label heading" htmlFor="formBasicFirstName" style={{ fontSize: "15px !important" }}>
                                            Year
                                        </Form.Label>
                                        <Select className='basic-single' defaultInputValue={defaultYear} value={selectedYear} onChange={(e) => { setSeletecYear(e); setSlectedMonth(''); }} options={yearList}></Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={6} lg={4} md={5} style={{ zIndex: "100 !important" }}>
                                    <Form.Group>
                                        <Form.Label className="form-view-label heading" htmlFor="formBasicFirstName">
                                            Month
                                        </Form.Label>
                                        <Select className='basic-single' defaultInputValue={defaultMonth} value={selectedMonth} onChange={(e) => { setDaysAccordingToMonth(e); setSlectedMonth(e) }} options={monthList} ></Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Container>

            {/* Target Achivements */}

            {
                (selectedYear && selectedMonth && initalTarget) && <Achievement givenTarget={initalTarget?.salesTargetDetails.givenTarget} achievedAmount={initalTarget?.salesTargetDetails.achievedTarget} />
            }

            <Container>
                {
                    (selectedMonth && initalTarget) && <Row className='d-flex justify-content-center align-items-center pe-2'>
                        <Col lg={10} className='mt-2 d-flex justify-content-end'>
                            <button className='btn btn-primary' onClick={handleSubmitDetails}>Submit</button>
                        </Col>
                    </Row>
                }
            </Container>
            {

                isSpinner == true ?
                    enableCustomShimmer ?
                        <>
                            {((initalTarget?.salesTargetDetails.givenTarget <= initalTarget?.salesTargetDetails.achievedTarget) && selectedMonth && selectedYear) &&
                                <Container>
                                    <Container>
                                        <Row className='d-flex justify-content-center mt-1'>
                                            <Col lg={10} sm={10} md={11} className='ps-2 pe-2'>
                                                <Accordion>
                                                    <Accordion.Item eventKey='1'>
                                                        <Accordion.Header className='alert d-block alert-info m-0 p-0 border-0 mb-0'>
                                                            <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                                            </symbol>
                                                            <i className="fa-solid fa-award heading mx-2" style={{ color: "#4f8b78" }} ></i><span className='text-center fw-bold'> Congratulations!!!</span>
                                                        </Accordion.Header>
                                                        <Accordion.Body className='m-0 p-0'>
                                                            <Row className='d-flex justify-content-center align-items-center mt-3  pb-3 m-0 mt-0 p-0'>
                                                                <Col lg={9} md={12} sm={12}>
                                                                    <Row >
                                                                        <Col lg={6} md={6} className='pt-2 d-flex justify-content-center'>
                                                                            <div style={{ height: '200px', width: "200px" }}>
                                                                                <CircularProgressbar strokeWidth={2} value={100} text={`${100}%`}  ></CircularProgressbar>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6} md={6} className='d-flex justify-content-between align-items-center '>
                                                                            <Container >
                                                                                <Row className=''>
                                                                                    <Col lg={12} md={12}>
                                                                                        <div className='fw-bold text-center heading'>Given Target : &#8377;{formatNumberWithDecimals(initalTarget?.salesTargetDetails.givenTarget)} </div>
                                                                                        <ProgressBar>
                                                                                            <ProgressBar style={{ backgroundColor: "#feeae4" }} animated now={100} />
                                                                                        </ProgressBar>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className='my-4 pt-2'>
                                                                                    <Col className='lead d-flex justify-content-center align-items-center h-100'>
                                                                                        <span className='heading fw-semibold'>In {initalTarget?.achivedTargetDetails.length} Days</span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={12} md={12}>
                                                                                        <ProgressBar className=''>
                                                                                            <ProgressBar style={{ backgroundColor: "#9aebe9" }} animated now={100} />
                                                                                        </ProgressBar>
                                                                                        <div className='fw-bold text-center heading'>Achieved Target : &#8377; {formatNumberWithDecimals(initalTarget?.salesTargetDetails.achievedTarget)} </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Container>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                            }


                            <Container className='mt-2'>
                                {
                                    (selectedMonth && selectedYear) &&
                                    <Container className='d-flex justify-content-center w-100'>
                                        <Row className='d-flex justify-content-center w-100'>
                                            <Col lg={10} sm={12} md={12}>
                                                <Table className="table">
                                                    <tbody>
                                                        {
                                                            daysInMonth.map((item, index) => (
                                                                <Row className='mt-1' key={index + 1}>
                                                                    <Col lg={12} className='rounded-2'>
                                                                        <Accordion defaultActiveKey={[weekOfMonth(moment(currentDate))]} flush>
                                                                            <Accordion.Item key={index} eventKey={index + 1}>
                                                                                <Accordion.Header>
                                                                                    <div className='fw-semibold text-start' style={{ color: '#4f8b78', fontSize: 'large', textAlign: 'center', width: "63%" }}>
                                                                                        Week : {index + 1}
                                                                                    </div>
                                                                                    <Badge pill style={{ backgroundColor: "var(--primary-color) !important" }} className='text-start ps-3 p-2'>
                                                                                        &#8377; {formatNumberWithDecimals([...item.map((sub) => sub.amount && sub?.amount)].reduce((a, b) => Number(a) + Number(b), 0))}
                                                                                    </Badge>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <ListGroup variant='flush'>
                                                                                        {
                                                                                            item?.map((subItem, subIndex) => (
                                                                                                <ListGroup.Item key={subIndex} className='d-flex justify-content-around'>
                                                                                                    <Col lg={6} md={6} sm={4} className='d-flex justify-content-center'>
                                                                                                        {moment(subItem?.date).format("DD/MM/YYYY")}
                                                                                                    </Col>
                                                                                                    <Col lg={6} md={6} sm={8} className='d-flex justify-content-center'>
                                                                                                        <span className='fw-bold fs-4 mx-2'>&#8377;</span><Form.Control type="text" className='w-50' value={formatNumberWithDecimals(subItem?.amount).replaceAll(" ", "")} onChange={(e) => { addAmountToSpecificRecord(e, index, subIndex) }} placeholder='Enter Amount ' />
                                                                                                    </Col>
                                                                                                </ListGroup.Item>
                                                                                            ))
                                                                                        }
                                                                                    </ListGroup>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        </Accordion>
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Container className='pb-5'>
                                                {
                                                    initalTarget &&
                                                    <Row className='d-flex justify-content-center'>
                                                        <Col lg={10} className='d-flex justify-content-end pe-0 p-1'>
                                                            <button className='btn btn-primary' onClick={handleSubmitDetails}>Submit </button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Container>
                                        </Row>
                                    </Container>
                                }
                            </Container >
                        </>

                        : (enableCustomShimmer === false && selectedMonth) ? <Container >
                            <Row className='d-flex justify-content-center align-items-center mt-4'>
                                <Col lg={10} >
                                    <div className='alert h5 alert-sm text-center  alert-danger'>
                                        {`No Sales Target for ${selectedMonth.label} `}
                                        <hr />
                                    </div>
                                </Col>
                            </Row>
                        </Container> : <Shimmer />

                    : <Shimmer />
            }
            <Toaster />
        </>
    )
}

export default Target




