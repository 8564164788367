import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'

const Confirm = (props) => {
    return (
        <Modal show={props.visibleModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => props.setVisibleModal(!props.visibleModal)}>
            <Alert variant="danger" className='mb-0'>
                <Alert.Heading>{props.title}</Alert.Heading>
                <p>
                    {props.message}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                    {props.table === "revoke" &&
                        <Button variant="danger" className='mx-2' onClick={props.leaveRevoked}>
                            Yes
                        </Button>
                    }
                    <button className='btn btn-primary' onClick={() => props.setVisibleModal(!props.visibleModal)}>
                        No
                    </button>
                </div>
            </Alert>
        </Modal>
    )
}

export default Confirm