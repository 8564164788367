import React from 'react'

const LeaveStatusButton = ({ status }) => {
    return (
        status.toLowerCase() === "approved"
            ?
            <button disabled className="btn btn-sm rounded-pill border-0 text-dark" style={{ backgroundColor: "#7ecff5" }}>{status}</button>
            : status.toLowerCase() === 'rejected'
                ?
                <button disabled className="btn btn-sm rounded-pill border-0 text-dark" style={{ backgroundColor: "#feeae4" }}>{status}</button>
                :
                <button disabled className="btn btn-sm rounded-pill border-0 text-dark" style={{ backgroundColor: "#e0c5f6" }}>{status}</button>
    )
}

export default LeaveStatusButton