import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import authApi from "../api/authApi";
import * as constants from '../constants/CONSTANT';
import { useNavigate } from "react-router-dom";
import { GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialFacebook, IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import { GoogleLogin } from '@react-oauth/google';
import Shimmer from "./common/Shimmer";
const Login = ({ isLogout, setIsLogout }) => {
  const navigate = useNavigate();
  const [isSpinner, setIsSpinner] = useState(false);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [show, setShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [validated, setValidated] = useState(true);

  const handleSocialLogin = async (response) => {
    const result = await authApi.social_login(response, response.access_token);
    if (result.success) {
      localStorage.setItem("token", result.authToken);
      navigate("/");
      setIsLogout(!isLogout);
    }
  }

  const checkRequredFields = () => {
    if (
      credentials.email &&
      credentials.email.trim() !== "" &&
      credentials.password &&
      credentials.password.trim() !== ""
    ) {
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkRequredFields()) {
      setValidated(false);
      return;
    }
    setIsSpinner(true);
    setShow(false);
    const result = await authApi.login(credentials.email, credentials.password);
    if (result.success) {
      localStorage.setItem("token", result.authToken);
      localStorage.setItem('product_category', JSON.stringify(constants.Product_CATEGORY_VALUES));
      localStorage.setItem('product_subcategory', JSON.stringify(constants.Product_SUBCATEGORY_VALUES));
      setShow(false);
      setErrorMessage("");
      setIsLogout(true);
      navigate("/")
    } else {
      setIsSpinner(false);
      setShow(true);
      setErrorMessage(result.errors);
    }

  }
  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }
  return !isSpinner ? (
    <Container className="d-flex justify-content-center align-items-center">
      <Row className="login-form p-3 mt-5">
        <Col lg={6} className="pt-5">
          <img src="login.jpg" style={{ width: "100%" }} />
        </Col>
        <Col lg={6} className="login-section">
          <div className="p-5 pt-4">
            <Form onSubmit={handleSubmit}>
              <div className="mt-2 text-center mb-3">
                <img
                  src="jajot.png"
                  style={{ width: "70px", height : "70px" }}
                  className="mb-2 rounded"
                />
                <div>Convert, before it fades away</div>
                <h3 className="my-2 mt-5">Login</h3>
              </div>
              <Alert variant="danger" show={show} className="error-alert">
                {errorMessage}
              </Alert>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="Enter email"
                  value={credentials.email}
                  style={validated ? { border: "1px solid #ced4da" } : { border: "1px solid tomato" }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"

                  value={credentials.password}
                  style={validated ? { border: "1px solid #ced4da" } : { border: "1px solid tomato" }}
                />
              </Form.Group>
              <div className="d-flex align-items-center">
                <Button variant="primary" type="submit" style={{ padding: "11px" }}>
                  Login
                </Button>
                <Container>                 
                </Container>
              </div>
            </Form>
            <div className="pt-4 text-center">
              <div className="pt-4">
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="text-center">
            <div className="text-center mt-3"> Powered by</div>
            <img src="indicrmlogo.png" width="150px" />
          </div>
        </Col>
      </Row>
    </Container>
  ) : <Shimmer />
}


export default Login
