import moment from 'moment';
import { Card, Col, Row } from 'react-bootstrap'
import { ShimmerTable } from 'react-shimmer-effects';
import toast, { Toaster } from 'react-hot-toast';
import JajotApi from '../../api/JajotApi';

const LeaveSelection = ({ setHoliDayActive, leaveCollection, notifyMembers, setLeaveCollection, formDetails, setFormDetails, setselectedusers }) => {
    const handleCheckboxChange = (e) => {
        e.stopPropagation();
        const { name, checked } = e.target;
        const updatedMainData = leaveCollection.map(row => {
            if ((name === 'full_day' || name === 'half_day') && !row.isWeekend && !row.isHoliday) {
                return { ...row, reason: formDetails.reason, comment: formDetails.comment, leaveType: checked ? (name === 'full_day' ? 'Full Day' : 'Half Day') : '' };
            }
            return row;
        });
        setLeaveCollection(updatedMainData);
    };

    const handleRadioChange = (rowIndex, leave_type, reason, comment) => {
        const updatedMainData = leaveCollection.map((row, index) => {
            if (index === rowIndex) {
                return { ...row, leaveType: leave_type, reason: reason, comment: comment };
            }
            return row;
        });
        setLeaveCollection(updatedMainData);
    }

    const handleSaveLeaveSection = async () => {
        const hasEmptySelectedOption = leaveCollection.some(item => !item.leaveType && !item.isHoliday && !item.isWeekend);

        if (hasEmptySelectedOption) {
            toast.error("Select a day for all dates.");
            return;
        }

        if (formDetails.reason) {
            for (let i of leaveCollection) {
                i.reason = formDetails.reason;
            }
        }

        const hasEmptyReason = leaveCollection.some(item => !item.reason && !item.isHoliday && !item.isWeekend);

        if (hasEmptyReason) {
            toast.error("Reason field is mandatory before applying.");
            return;
        }

        notifyMembers = notifyMembers.map((item) => item.value.slice(item?.value.indexOf("-") + 1));        
        let addedRecord = { "base64File": 'base64String', "contentType": 'fileDetails.type', "notifyMembers": notifyMembers, "leaveDetails": leaveCollection };

        let response = await JajotApi.createLeavesRecords(addedRecord)
        if (response?.success) {
            setselectedusers([]);
            const successMessage = 'Leaves saved successfully.';
            setFormDetails((prev) => []);
            setHoliDayActive((prev) => !prev);
            const isSuccessMessage = response.records.toLowerCase().includes(successMessage.toLowerCase());
            isSuccessMessage ? toast.success(response.records) : toast.error(response.records);
        }
    }
    return (
        <>
            <Toaster />
            <Col lg={6} sm={12} xs={12} className="mb-3">
                {leaveCollection.length > 0 ? (
                    <Card className='h-100' style={{ height: "27rem", borderTop: "3px solid #4f6479", }}>
                        <Card.Body style={{ height: "21rem" }}>
                            <Row>
                                <Col lg={12} sm={12} xs={12}>
                                    <div className="table-container" style={{ maxHeight: "20rem", overflowY: "auto" }}>
                                        <table className="table" >
                                            <thead>
                                                <tr align='center'>
                                                    <th>Date</th>
                                                    <th>
                                                        Full Day
                                                        <input
                                                            className='mx-1 mt-1'
                                                            type="checkbox"
                                                            name='full_day'
                                                            checked={leaveCollection.filter(row => !row.isWeekend && !row.isHoliday).every(row => row.leaveType === "Full Day")}
                                                            disabled={leaveCollection.every(row => row.isWeekend || row.isHoliday)} // Disable if all rows are weekends or holidays
                                                            onChange={handleCheckboxChange} />
                                                    </th>
                                                    <th align="center">
                                                        Half Day
                                                        <input
                                                            className='mx-1 mt-1'
                                                            name='half_day'
                                                            type="checkbox"
                                                            checked={leaveCollection.filter(row => !row.isWeekend && !row.isHoliday).every(row => row.leaveType === "Half Day")}
                                                            disabled={leaveCollection.every(row => row.isWeekend || row.isHoliday)} // Disable if all rows are weekends or holidays
                                                            onChange={handleCheckboxChange} />
                                                    </th>
                                                    <th>Optional</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {leaveCollection && leaveCollection.map((row, rowIndex) => (//rgb(248 249 199 / 68%)
                                                    <tr key={rowIndex} align='center' >
                                                        <td style={{ backgroundColor: row.isWeekend ? "#FEE2E5" : row.isHoliday ? "#E1F0FE" : row.isOptional ? "rgb(248 249 199 / 68%)" : "transparent" }}>
                                                            {moment(row?.leaveDate).format('DD-MM-YYYY')}
                                                        </td>
                                                        <td style={{ backgroundColor: row.isWeekend ? "#FEE2E5" : row.isHoliday ? "#E1F0FE" : row.isOptional ? "rgb(248 249 199 / 68%)" : "transparent" }}>
                                                            <input
                                                                className='mx-1'
                                                                type="radio"
                                                                name={`radio-${rowIndex}`}
                                                                value="Full Day"
                                                                checked={row.leaveType === "Full Day"}
                                                                disabled={row.isWeekend || row.isHoliday}
                                                                onChange={() => handleRadioChange(rowIndex, "Full Day", row.reason, row.comment)}
                                                            // style={{ transform: row.isWeekend || row.isHoliday ? 'scale(1.1)' : 'none' }} // Apply scale(1) when disabled
                                                            />
                                                        </td>
                                                        <td style={{ backgroundColor: row.isWeekend ? "#FEE2E5" : row.isHoliday ? "#E1F0FE" : row.isOptional ? "rgb(248 249 199 / 68%)" : "transparent" }}>
                                                            <input
                                                                className='mx-1'
                                                                type="radio"
                                                                name={`radio-${rowIndex}`}
                                                                value="Half Day"
                                                                checked={row.leaveType === "Half Day"}
                                                                disabled={row.isWeekend || row.isHoliday}
                                                                onChange={() => handleRadioChange(rowIndex, "Half Day", row.reason, row.comment)}
                                                            // style={{ transform: row.isWeekend || row.isHoliday ? 'scale(1.1)' : 'none' }} // Apply scale(1) when disabled
                                                            />
                                                        </td>
                                                        <td style={{ backgroundColor: row.isWeekend ? "#FEE2E5" : row.isHoliday ? "#E1F0FE" : row.isOptional ? 'rgb(248 249 199 / 68%)' : "transparent" }}>
                                                            <input
                                                                className='mx-1'
                                                                type="radio"
                                                                name={`radio-${rowIndex}`}
                                                                value="Optional"
                                                                checked={row.leaveType === "Optional"}
                                                                disabled={row.isWeekend || row.isHoliday || !row.isOptional}
                                                                onChange={() => handleRadioChange(rowIndex, "Optional", row.reason, row.comment)}
                                                            // style={{ transform: row.isWeekend || row.isHoliday ? 'scale(1.1)' : 'none' }} // Apply scale(1) when disabled
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>

                        <Card.Footer style={{ backgroundColor: "white" }} >
                            <Row className='mt-2'>
                                <Col lg={10} sm={10} xs={10} className="mt-2 justify-content-start">
                                    <span className='mx-1'>
                                        <svg width="20" height="10">
                                            <rect width="20" height="10" style={{ fill: "#FEE2E5" }} />
                                        </svg>
                                        <span> Weekend</span>
                                    </span>
                                    <span className='mx-1'>
                                        <svg width="20" height="10">
                                            <rect width="20" height="10" style={{ fill: "#E1F0FE" }} />
                                        </svg>
                                        <span> Holiday</span>
                                    </span>
                                    <span className='mx-1'>
                                        <svg width="20" height="10">
                                            <rect width="20" height="10" style={{ fill: "rgb(248 249 199 / 68%)" }} />
                                        </svg>
                                        <span> Optional</span>
                                    </span>
                                </Col>

                                <Col lg={2} sm={2} xs={2} className="justify-content-end">
                                    <button className='btn btn-primary float-end mb-2' disabled={leaveCollection.every(row => row.isWeekend || row.isHoliday)} onClick={handleSaveLeaveSection} >
                                        Apply
                                    </button>
                                </Col>
                            </Row>
                        </Card.Footer>

                    </Card>
                ) :
                    <div className='mt-5'>
                        <ShimmerTable row={1} col={3} />
                        <p className='text-center'>Click "Next" to Proceed</p>
                        <div className="custom-shimmer-class mt-4">
                            <ShimmerTable row={1} col={3} />
                        </div>
                    </ div>
                }
            </Col>
        </>
    )
}

export default LeaveSelection