import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Container, Row, Form } from 'react-bootstrap'
import JajotApi from '../../api/JajotApi';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Heading from '../common/Heading';
import Shimmer from '../common/Shimmer';

const Attendance = () => {    
    const formRef = useRef(null);
    const formRefrence = useRef(null);
    const fileInputRef = useRef(null);
    const [isSpinner, setIsSpinner] = useState();
    const [isMarked, setIsMarked] = useState(false);
    const [fileError, setFileError] = useState('');
    const [imageBase64, setImageBase64] = useState('');
    const [fileDetails, setFileDetails] = useState({ name: '', type: '' });
    const [fileErrorLimit, setFileErrorLimit] = useState('');
    const [employeeDetail, setEmployeeDetail] = useState();
    const [attendanceExist, setAttendanceExist] = useState(false);
    const [attendanceInfo, setAttendanceInfo] = useState();
    const [remarks, handleremarkChange] = useState('');
    const [locationDetails, setLocationDetails] = useState({
        latitude: null,
        longitude: null,
        address: "",
    });

    useEffect(() => {
        setIsSpinner(true);
        let empDetails = jwtDecode(localStorage.getItem('token'));
        setEmployeeDetail(empDetails);
        (async () => {
            let result = await JajotApi.getAllAttendance(empDetails.contactDetails.Id);
            setIsSpinner(false);
            if (result.success) {
                if (result?.record?.employeeId !== null) {                    
                    setAttendanceExist(true);
                    setAttendanceInfo(result?.record);
                }
            }
        })();
    }, [isMarked])

    const fileHandleChange = (event) => {
        const file = event.target.files[0];
        const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        const maxSize = 3 * 1024 * 1024; // 3 MB in bytes
        if (file) {
            if (!allowedFormats.includes(file.type)) {
                formRef.current.reset();
                setFileDetails({ name: '', type: '' });
                setFileErrorLimit('');
                setFileError('Only JPG, JPEG, and PNG formats are allowed');
                event.target.value = null;
            }
            else if (file.size > maxSize) {
                toast.error('File size exceeds please select the corect size file');
                formRefrence.current.reset()
                setFileDetails({ name: '', type: '' });
                setFileErrorLimit('File size exceeds the maximum limit of 3 MB');
                setFileError('');
                setImageBase64(undefined);
            }
            else {
                const reader = new FileReader();
                reader.onloadend = () => {                    
                    setImageBase64(reader?.result)
                };
                reader.readAsDataURL(file);
                setFileDetails({ name: file.name, type: file.type.split("/")[1] });
                setFileError('');
                setFileErrorLimit('');
            }
        }
        else {
            setFileError('');
            setFileDetails({ name: '', type: '' });
        }
    }

    const myMap = useCallback(() => {
        try {
            navigator.geolocation.getCurrentPosition((position) => {
                const currentCoords = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                setLocationDetails((prev) => ({
                    ...prev,
                    latitude: currentCoords.lat,
                    longitude: currentCoords.lng,
                }));

                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ location: currentCoords }, (results, status) => {
                    if (status === "OK" && results[0]) {
                        const formattedAddress = results[0].formatted_address;
                        setLocationDetails((prev) => ({
                            ...prev,
                            address: formattedAddress.replace(/,?\s?[A-Z0-9]{4}\+[A-Z0-9]{3}/, ''),
                        }));
                    } else {
                        console.error("Geocode was not successful for the following reason : ", status);
                    }
                    const map = new window.google.maps.Map(document.getElementById("googleMap"), {
                        zoom: 14,
                        center: currentCoords,
                    });
                    const marker = new window.google.maps.Marker({ position: currentCoords });
                    marker.setMap(map);
                });
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleAddressChange = (e) => {
        setLocationDetails((prev) => ({ ...prev, address: e.target.value }));
    }

    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    const handleSubmit = async () => {
        if (!locationDetails?.address) {
            toast.error('Required field is empty');
            return;
        }
        const base64 = imageBase64.toString().replace(/^data:(.*,)?/, "");
        let result = await JajotApi.saveAttendance({ employeeId: employeeDetail.contactDetails.Id, attdate: moment(new Date()).format('YYYY-MM-DDThh:mm:ss'), address: locationDetails.address, remarks: remarks, latitude: locationDetails.latitude, longitude: locationDetails.longitude, "fileName": (fileDetails.name.slice(0, fileDetails.name.indexOf(".")) + "-" + new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear()) + "." + fileDetails.type, "contentType": fileDetails.type, "base64File": base64 === '' ? '' : base64 }, employeeDetail.contactDetails.Id);
        if (result?.success && result?.message === 'Attendance is successfully marked') {
            setIsMarked(!isMarked);
            toast.success(result?.message)
        } else {
            toast.error("Issues in attendance marking");
        }
    }

    useEffect(() => {
        try {
            if (!window.google) {           
                const googleMapScript = document.createElement("script");
                googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDtztwOW6bufAlbBWfO1k5FdZTkpUP_gmI&callback=initMap`;
                googleMapScript.async = true;
                googleMapScript.defer = true;
                window.initMap = myMap;
                document.body.appendChild(googleMapScript);
            } else {
                myMap();
            }

        } catch (error) {
            console.log(error)
        }
    }, [myMap, isMarked]);

    return (
        <>
            <Heading headingName={"Attendance"} />

            <Container className='p-4'>
                <Row className='d-flex justify-content-center'>
                    <Col lg={7} md={6} sm={12}>
                        <Row className='form-view d-flex justify-content-start'>
                            <Col lg={12} className='d-flex justify-content-start ps-0'>
                                <div id="googleMap" style={{ width: '100%', height: '250px', border : "1px solid black" }}> </div>
                            </Col>
                        </Row>

                        {
                            isSpinner === false ?
                                <>
                                    <Row className='mt-3'>
                                        <Col lg={12} md={12} sm={12} className='ps-0'>
                                            <Form.Group className="" style={{ padding: '0' }} >
                                                <Form.Label className="form-view-label" htmlFor="formAddress">Address</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="address"
                                                    placeholder="Enter Address"
                                                    disabled={attendanceInfo?.address ? true : false}
                                                    value={!attendanceInfo?.address ? locationDetails?.address[0] === ',' ? locationDetails?.address.replace(",", "") : locationDetails?.address : attendanceInfo?.address}
                                                    onChange={handleAddressChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col lg={12} md={12} sm={12} className='ps-0'>
                                            <Form.Group className="" style={{ padding: '0' }}>
                                                <Form.Label className="form-view-label" >
                                                    Remarks
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="remarks"
                                                    disabled={attendanceInfo?.remarks ? true : false}
                                                    value={attendanceInfo?.remarks}
                                                    placeholder="Enter remarks.."
                                                    onChange={(e) => handleremarkChange(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {
                                        !attendanceExist &&
                                        <Row className="my-3">
                                            <Col lg={10} md={10} className='d-flex justify-content-between ps-0'>
                                                <button className='btn btn-primary' onClick={handleSubmit}>
                                                    Check-In
                                                </button>
                                            </Col>
                                        </Row>
                                    }
                                </>

                                : isSpinner === true && <Shimmer />
                        }

                    </Col>
                    {
                        <Col lg={3} sm={9} md={4} className='d-flex align-items-start justify-content-end pe-0'>
                            {

                                attendanceInfo !== undefined ?
                                    <Form ref={formRefrence} className='mb-0 w-100 d-flex justify-content-center'  >
                                        <Form.Group controlId="formFile d-flex justify-content-center" style={{ border: "1px solid red !important" }} className='w-75 border rounded p-2' >
                                            {
                                                attendanceInfo?.publicUrl !== null ?
                                                    <Form.Label className=' w-100 d-fex justify-content-center' style={{ cursor: "pointer", border: "0px dotted" }}>
                                                        <center>
                                                            <div className='d-flex justify-content-center'>
                                                                <span>
                                                                    <Card className='mb-2'>
                                                                        <Card.Body>
                                                                            <img src={attendanceInfo?.publicUrl} className='img-fluid' alt='img' />
                                                                        </Card.Body>
                                                                    </Card>
                                                                </span>
                                                            </div>
                                                        </center>
                                                    </Form.Label>
                                                    : <img src="/Image_not_available.png" alt='run' className='img-fluid' />
                                            }
                                        </Form.Group>
                                    </Form>
                                    :
                                    <Form ref={formRefrence} className='mb-0 w-100 d-flex justify-content-center'  >
                                        <Form.Group controlId="formFile d-flex justify-content-center" style={{ border: "1px solid red !important" }} className='w-75 border rounded p-2' >
                                            <Form.Label className=' w-100 d-fex justify-content-center' style={{ cursor: "pointer", border: "0px dotted" }}>
                                                <center>
                                                    <div className='d-flex justify-content-center'>
                                                        <span>
                                                            {
                                                                !imageBase64 ?
                                                                    <><i className="fa-solid fa-upload fs-4 p-2 mt-4 border rounded-circle cursor-pointer" onChange={handleFileInputClick}></i> <br /></>
                                                                    :
                                                                    <Card className='' >
                                                                        <Card.Body >
                                                                            <img src={imageBase64} alt='img' className='img-fluid' />
                                                                        </Card.Body>
                                                                    </Card>
                                                            }
                                                            {fileDetails?.name ? '' : "Upload Image"}
                                                        </span>
                                                    </div>
                                                </center>
                                                {
                                                    !imageBase64 &&
                                                    <hr className='w-100 bg-danger' />
                                                }
                                            </Form.Label>
                                            {
                                                !imageBase64 &&
                                                <>
                                                    <Form.Control hidden type="file" name="file" accept=".jpg, .jpeg, .png" onChange={fileHandleChange} ref={fileInputRef} />
                                                    <br />
                                                </>
                                            }
                                            {
                                                !imageBase64 &&
                                                <>
                                                    <Form.Text className={fileError ? 'text-danger' : 'text-black'}>Only <span style={{ fontSize: "10px", fontWeight: "600" }}>JPG, JPEG, and PNG</span> formats are allowed.</Form.Text><br />
                                                    <Form.Text className={fileErrorLimit ? 'text-danger' : 'text-black'}>Max File size: <span style={{ fontSize: "10px", fontWeight: "600" }}>3 MB</span></Form.Text>
                                                </>
                                            }
                                        </Form.Group>
                                    </Form>
                            }
                        </Col>
                    }
                </Row>
            </Container>
            <Toaster />
        </>
    )
}

export default Attendance;