import jwtDecode from 'jwt-decode';
import React, { useState, useRef, useEffect } from 'react'
import { Image, Modal } from 'react-bootstrap'
import { Col, Form, Row } from "react-bootstrap";
import JajotApi from '../../api/JajotApi';
import toast, { Toaster } from 'react-hot-toast';


const UpdateImage = ({ changeImage, setChangeImage, previousImage, show, onHide, imageRefresh }) => {
    const [body, setBody] = useState(show);
    const [base64String, setBase64String] = useState('');
    const [fileDetails, setFileDetails] = useState({ name: '', type: '' });
    const fileInputRef = useRef(null);
    const formRef = useRef(null);    
    const [fileError, setFileError] = useState('');
    const [fileErrorLimit, setFileErrorLimit] = useState('');
    const [isFormChanged, setIsFormChanged] = useState(true);
    const [isSpinner, setIsSpinner] = useState(true);

    useEffect(() => {

    }, []);

    const fileHandleChange = (event) => {
        const file = event.target.files[0];
        const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        const maxSize = 3 * 1024 * 1024; // 3 MB in bytes                
        if (file) {
            // if (!file.name.includes(fileName)) {
            //     formRef.current.reset();
            //     setFileDetails({ name: '', type: '' });
            //     // setFileContainError('File name does not contain the required string');
            //     setFileErrorLimit('');
            //     setFileError('');
            //     event.target.value = null;
            // }
            if (!allowedFormats.includes(file.type)) {
                formRef.current.reset();
                setFileDetails({ name: '', type: '' });
                // setFileContainError('');
                setFileErrorLimit('');
                setFileError('Only JPG, JPEG, and PNG formats are allowed');
                event.target.value = null;
            }
            else if (file.size > maxSize) {
                formRef.current.reset();
                setFileDetails({ name: '', type: '' });
                // setFileContainError('');
                setFileError('');
                setFileErrorLimit('File size exceeds the maximum limit of 3 MB');
            }
            else {
                setBody({ ...body, url: URL.createObjectURL(event.target.files[0]) });
                const reader = new FileReader();
                reader.onloadend = () => {
                    const fileContent = reader.result.split(",")[1];
                    setBase64String(fileContent);
                };
                reader.readAsDataURL(file);
                setFileDetails({ name: file.name, type: file.type.split("/")[1] });
                // setFileContainError('');
                setFileError('');
                setFileErrorLimit('');
            }
        }
        else { // If no file is selected, clear the error message and file name
            // setFileContainError('');
            setFileError('');
            setFileDetails({ name: '', type: '' });
        }
    }

    // File uplaod
    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    // handle submit
    const handleSubmit = async () => {
        if (fileDetails.type && base64String) {
            setIsSpinner(false)
            setIsFormChanged(false);
            const fileNameWithoutExtension = fileDetails.name.split('.')[0];
            let userInfo = jwtDecode(localStorage.getItem('token'));
            let imgData = { "employeeId": userInfo.contactDetails.Id, "empName": userInfo.username, "fileName": fileNameWithoutExtension, "contentType": fileDetails.type, "base64File": base64String };
            const result = await JajotApi.updateEmployeeProfileImage(imgData);
            if (result.success) {
                setIsSpinner(true);
                onHide(!show);
                setChangeImage(!changeImage);
                toast.success('Profile Picture updated successfully.');
            } else {
                toast.error(`Can't able to update the profile`);
                setIsSpinner(false);
            }
        } else {
            toast.error('Please select a new file.');
        }
    }

    return (
        <>
            <Modal show={show} animation={false} centered size='md' >
                <Modal.Header closeButton onClick={() => onHide(!show)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Upload Image
                    </Modal.Title>
                </Modal.Header>
                {isSpinner ?
                    <Modal.Body>
                        <Row className='text-center'>
                            <Col lg={12} sm={12} xs={12}>
                                <Form noValidate >
                                    <Image variant="top" src={body.url ? body.url : previousImage} thumbnail style={{ width: "40%" }} >
                                    </Image>
                                </Form>
                            </Col>
                        </Row>
                        <Row className='text-center'>
                            <Col lg={12} sm={12} xs={12}>
                                <Form ref={formRef} className='mb-0' >
                                    <Form.Group controlId="formFile" >
                                        <Form.Label className='p-2' style={{ cursor: "pointer", border: "1px dotted", color: "rgb(201, 188, 188)", width: "40%" }} title='Upload Image'>
                                            <i className="fa-solid fa-cloud-arrow-up mx-5" style={{ cursor: "pointer", fontSize: "30px", color: "rgb(144 231 245)" }} onChange={handleFileInputClick}></i><br />
                                            {fileDetails?.name && <span>{fileDetails?.name}</span>}
                                        </Form.Label>
                                        <Form.Control hidden type="file" name="file" accept=".jpg, .jpeg, .png" // Specify accepted file formats
                                            onChange={fileHandleChange}
                                            ref={fileInputRef}
                                        /><br />
                                        {/* <Form.Text className={fileContainError ? 'text-danger' : 'text-black'}>File name must contain the string <span style={{ fontSize: "10px", fontWeight: "600" }}>'profile-logo'</span> before, after, or between.</Form.Text><br /> */}
                                        <Form.Text className={fileError ? 'text-danger' : 'text-black'}>Only <span style={{ fontSize: "10px", fontWeight: "600" }}>JPG, JPEG, and PNG</span> formats are allowed.</Form.Text><br />
                                        <Form.Text className={fileErrorLimit ? 'text-danger' : 'text-black'}>Max File size: <span style={{ fontSize: "10px", fontWeight: "600" }}>3 MB</span></Form.Text>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                    :
                    <div>
                        <div className="sk-cube-grid">
                            <div className="sk-cube sk-cube1"></div>
                            <div className="sk-cube sk-cube2"></div>
                            <div className="sk-cube sk-cube3"></div>
                            <div className="sk-cube sk-cube4"></div>
                            <div className="sk-cube sk-cube5"></div>
                            <div className="sk-cube sk-cube6"></div>
                            <div className="sk-cube sk-cube7"></div>
                            <div className="sk-cube sk-cube8"></div>
                            <div className="sk-cube sk-cube9"></div>
                        </div>
                    </div>
                }
                <Modal.Footer>
                    <button className='btn btn-primary' onClick={() => { onHide(!show) }} >Close</button>
                    <button className='btn btn-primary' onClick={handleSubmit} >Save</button>
                </Modal.Footer>
            </Modal>
            <Toaster position="top-center" reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 3000,
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }} />
        </>
    )
}

export default UpdateImage