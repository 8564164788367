import React, { useEffect, useState } from 'react'
import { Accordion, Col, Container, ListGroup, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import JajotApi from '../../api/JajotApi'
import Shimmer from '../common/Shimmer';
import toast, { Toaster } from 'react-hot-toast';
import Heading from '../common/Heading';

const Payroll = () => {
  const location = useLocation();
  const [payslipRecord, setPaySlipRecord] = useState();
  const navigate = useNavigate();
  const paySlipView = (record) => {    
    navigate(`/payroll/view/${record.payrollName.replaceAll(" ", "-")}`, { state: record });
  }

  useEffect(() => {
    (async () => {
      let result = await JajotApi.getPaySlipRecords();      
      if (result?.success && result?.records.length > 0) {
        setPaySlipRecord(result?.records);
      } else {
        setPaySlipRecord([{ payrolls: [] }]);
      }
    })();
  }, [])

  // Download payslip PDF
  const handleClickPaySlipDownload = async (payroll) => {
    
    try {
      if (payroll) {      
        const newWindow = window.open(payroll?.downloadUrl, '_blank');
        if (newWindow) {  
          newWindow.addEventListener('load', () => {
            const downloadLink = newWindow.document.createElement('a');
            downloadLink.href = payroll?.downloadUrl;
            let attachementname = location?.state?.attachmentName
            downloadLink.download = attachementname;
            downloadLink.click();
          });
        } else {
          console.error('Failed to open a new window to download the file.');
        }
      } else {
        toast.error("Attachment not found.");
      }
    } catch (error) {
      console.error('Error during PDF download:', error);
    }
  }

  return (
    <>
      {/* Payroll Heading */}
      <Heading headingName={"View PaySlips"} />

      {/* Payroll Main Section */}

      {
        payslipRecord !== undefined ?
          <Container className='mt-2'>
            {
              payslipRecord?.map((item, index) => (
                <Row className='d-flex justify-content-center' key={index}>
                  <Col lg={10} xs={12} sm={12} style={{ paddingBottom: '70px' }}>
                    {item?.payrolls.length > 0 ?
                      <Accordion>
                        <Accordion.Item eventKey='1' key={1}>
                          {/* <div> */}
                          <Accordion.Header>
                            <span className='fw-semibold' style={{ color: 'var(--primary-color)', fontSize: 'larger', textAlign: 'center' }}>
                              {item?.financialYear}
                            </span>
                          </Accordion.Header>
                          {/* </div> */}
                          <Accordion.Body>
                            <ListGroup variant='flush'>
                              {
                                item?.payrolls?.map((value, subIndex) => (
                                  <ListGroup.Item key={subIndex}>
                                    <span className='me-2' style={{ display: 'inline-block' }}>
                                      <i className='fa-regular fa-file-lines heading' style={{ color: '#03a9f4', cursor: "pointer" }}></i>
                                    </span>
                                    <span className='fw-semibold d-flex justify-content-between w-100 ' style={{ color: '#605C68', display: 'inline-block', cursor: "pointer" }}>
                                      <span onClick={() => paySlipView(value)} style={{fontSize : '14.4px'}}>
                                        {value?.payrollName}
                                      </span>
                                      <span style={{ color: "#4f8b78" }}>
                                        <i className="fa-solid fa-download ms-4 pointer heading" title='Download' style={{ cursor: "pointer" }} onClick={() => handleClickPaySlipDownload(value)}></i>
                                      </span>
                                    </span>
                                  </ListGroup.Item>
                                ))
                              }
                            </ListGroup>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      : <ListGroup variant='flush'>
                        <ListGroup.Item>
                          <span className='fw-semibold pointer' style={{ color: '#605C68', fontSize: 'larger' }}>
                            Record Does Not Exist.
                          </span>
                        </ListGroup.Item>
                      </ListGroup>
                    }
                  </Col>
                </Row>
              ))
            }
          </Container>
          : <Shimmer />
      }
      <Toaster />
    </>
  )
}

export default Payroll