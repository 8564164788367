import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import JajotApi from '../../api/JajotApi';
import moment from 'moment';
import Shimmer from '../common/Shimmer';
import numberToEnglishWord from '../common/helper';

const PayrollView = () => {

    const navigate = useNavigate();
    const location = useLocation();
    
    // Payroll details 
    const [payrollDetails, setPayrollDetails] = useState();    

    const formatNumberWithDecimals = (number) => {
        const formattedNumber = number.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return `Rs. ${formattedNumber}`;
    }

    const handleClickPaySlipDownload = async () => {
        try {
            let result = await JajotApi.getPayrollPDF(payrollDetails.Id);
            if (result.success) {
                const newWindow = window.open(result.records?.downloadUrl, '_blank');
                if (newWindow) {
                    newWindow.addEventListener('load', () => {
                        const downloadLink = newWindow.document.createElement('a');
                        downloadLink.href = location.state?.downloadUrl;
                        let attachementname = location?.state?.attachmentName
                        downloadLink.download = attachementname;
                        downloadLink.click();
                    });
                } else {
                    console.error('Failed to open a new window to download the file.');
                }
            }
        } catch (error) {
            console.error('Error during PDF download:', error);
        }
    }

    useEffect(() => {
        let month = window.location.href.slice(window.location.href.lastIndexOf("/") + 1);
        let months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"]
        if (!(months.includes(month.slice(0, month.indexOf("-")).toLowerCase()))) {
            localStorage.removeItem('token');
            navigate("/");
        }
        (async () => {
            let month = moment().month(window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.lastIndexOf("-"))).format("MM");
            let year = window.location.href.slice(window.location.href.lastIndexOf("-") + 1);
            let record = await JajotApi.getPaySlipViewRecord(year + "-" + month + "-01");            
            if (record?.success) {
                setPayrollDetails(record?.records);
            }
        })();
    }, [])

    return payrollDetails !== undefined ? (
        <>
            <Container className='mt-3'>
                <Row className='p-3 mx-3' style={{ backgroundColor: "white", borderRadius: "5px" }}>
                    <Col lg={10} sm={8} xs={8}>
                        <span className='fw-semibold' style={{ color: "#605C68", fontSize: "1.2rem" }}>Payslip for the period of</span>
                        <span className='fw-bold mx-2' style={{ color: "#4f8b78", fontSize: "1.2rem" }}>
                            {moment(payrollDetails?.Salary_Date__c).format('MMMM YYYY')}
                        </span>
                    </Col>
                    <Col lg={2} sm={4} xs={4} className="text-end">
                        <span style={{ color: "#4f8b78" }}>
                            <i className="fa-solid fa-download mx-3 pointer" title='Download' style={{ fontSize: "1.2rem", cursor: "pointer" }} onClick={handleClickPaySlipDownload}></i>
                            <i className="fa-solid fa-arrow-left pointer" title='Back' style={{ fontSize: "1.2rem", cursor: "pointer" }} onClick={() => navigate("/payroll")}></i>
                        </span>
                    </Col>
                </Row>
            </Container>
            {
                payrollDetails !== undefined ?
                    <>
                        <Container className='mt-3'>
                            <Row className='p-2 mx-3' style={{ backgroundColor: "white", borderRadius: "5px" }}>
                                <Col lg={12} >
                                    <Row>
                                        <Col lg={2} sm={12} xs={12} className='p-1'>
                                            <div className='text-center p-2' style={{ background: "#E1F0FE", borderRadius: "5px" }}>
                                                <h5 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollDetails?.Total_Earning__c) == null ? 'Rs. 0.0' : formatNumberWithDecimals(payrollDetails?.Total_Earning__c)}</h5>
                                                <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Total Earning</p>
                                            </div>
                                        </Col>  

                                        <Col lg={2} sm={12} xs={12} className='p-1'>
                                            <div className='text-center p-2 mx-1' style={{ background: "#FEF3DE", borderRadius: "5px" }}>
                                                <h5 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollDetails?.Total_Deduction__c) == null ? 'Rs. 0.0' : formatNumberWithDecimals(payrollDetails?.Total_Deduction__c)}</h5>
                                                <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Total Deductions</p>
                                            </div>
                                        </Col>

                                        <Col lg={2} sm={12} xs={12} className='p-1'>
                                            <div className='text-center p-2 mx-1' style={{ background: "#C9F6F5", borderRadius: "5px" }}>
                                                <h5 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollDetails?.Net_Payable_Amount__c) == null ? 'Rs. 0.0' : formatNumberWithDecimals(payrollDetails?.Net_Payable_Amount__c)}</h5>
                                                <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Netpayable Income</p>
                                            </div>

                                        </Col>

                                        <Col lg={2} sm={12} xs={12} className='p-1'>
                                            <div className='text-center p-2 mx-1' style={{ background: "#FEE2E5", borderRadius: "5px" }}>
                                                <h5 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>
                                                    {moment(payrollDetails?.Salary_Date__c).format('MMMM YYYY')}
                                                </h5>
                                                <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Salary Period</p>
                                            </div>
                                        </Col>

                                        <Col lg={2} sm={12} xs={12} className='p-1'>
                                            <div className='text-center p-2 mx-1' style={{ background: "#EEE5FE", borderRadius: "5px" }}>
                                                <h4 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollDetails?.Total_Leaves__c) == null ? '0' : (payrollDetails?.Total_Leaves__c)?.toFixed(1)}</h4>
                                                <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Total Leaves</p>
                                            </div>
                                        </Col>

                                        <Col lg={2} sm={12} xs={12} className='p-1'>
                                            <div className='text-center p-2' style={{ background: "#E2EEFF", borderRadius: "5px" }}>
                                                <h4 className="fw-bold" style={{ paddingTop: "2px", color: "#796C9A" }}>{(payrollDetails?.Common_Leaves__c) == null ? '0' : (payrollDetails?.Common_Leaves__c)?.toFixed(1)}</h4>
                                                <p style={{ marginBottom: "0.2rem", color: "#978C86" }}>Common Leaves</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>

                        <Container className='mt-2'>
                            <Row>
                                <Col lg={6} sm={12} xs={12} className='h-75'>
                                    <Row className='mx-3 mt-2 ' style={{ backgroundColor: "white", borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 1px" }}>
                                        <span className="p-2 mx-1 heading">Earnings</span>
                                        <div className="card-body mx-2 mb-4 ">
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <tbody className='p-1'>
                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                            <td>Basic</td>
                                                            <td className='text-end'>
                                                                {payrollDetails?.Employee_Salary__r?.Basic__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Employee_Salary__r?.Basic__c / 12)}
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                            <td>HRA</td>
                                                            <td className='text-end'>
                                                                {payrollDetails?.Employee_Salary__r?.HRA__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Employee_Salary__r?.HRA__c / 12)}
                                                            </td>
                                                        </tr>
                                                        <>
                                                            {/* <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td>Internet Allowance</td>
                                                                <td className='text-end'>
                                                                    {payrollDetails?.Employee_Salary__r?.Internet_Allow__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Employee_Salary__r?.Internet_Allow__c / 12)}
                                                                </td>
                                                            </tr> */}
                                                            {/* <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                                <td >LTA</td>
                                                                <td className="text-end">
                                                                    {payrollDetails?.Employee_Salary__r?.LTA__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Employee_Salary__r?.LTA__c / 12)}
                                                                </td>
                                                            </tr> */}
                                                        </>

                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                            <td >Special Allowance</td>
                                                            <td className="text-end">
                                                                {payrollDetails?.Employee_Salary__r?.Special_Allow__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Employee_Salary__r?.Special_Allow__c / 12)}
                                                            </td>
                                                        </tr>                                                                                                                                                                 

                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                            <td >Other Convenyance</td>
                                                            <td className="text-end">
                                                                {(payrollDetails?.Other_Convenience__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Other_Convenience__c)}
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)", visibility : "hidden" }} >
                                                            <td >Dummy</td>
                                                            <td className="text-end">Dummy</td>
                                                        </tr>  
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="card-footer d-flex justify-content-between bg-white pt-3 mt-3 pl-4 pr-4"
                                            style={{ borderTop: "1px solid #c9bcbc", paddingBottom: "15px", color: "dimgrey" }}>
                                            <Col lg={9} sm={7} xs={7}>
                                                <span className='mx-3 fw-semibold'>Total Earning</span>
                                            </Col>
                                            <Col lg={3} sm={5} xs={5}>
                                                <h6 className='mx-3 text-end fw-semibold' style={{ color: "#4f8b78" }}>
                                                    {payrollDetails?.Total_Earning__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Total_Earning__c)}
                                                </h6>
                                            </Col>
                                        </div>
                                    </Row>
                                </Col>

                                <Col lg={6} sm={12} xs={12} style={{ height: "29rem" }} className='h-75'>
                                    <Row className='mx-3 mt-2' style={{ backgroundColor: "white", borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 1px" }}>
                                        <span className="p-2 mx-1 heading">Deductions</span>
                                        <div className="card-body mx-2">
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <tbody className='p-1'>                                                       
                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                            <td >Loan</td>
                                                            <td className="text-end">
                                                                {(payrollDetails?.Loan__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Loan__c)}
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                            <td >T.D.S.</td>
                                                            <td className="text-end">
                                                                {(payrollDetails?.TDS__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.TDS__c)}
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                            <td >Leave Deduction</td>
                                                            <td className="text-end">
                                                                {(payrollDetails?.Leave_Deduction__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Leave_Deduction__c)}
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                            <td >Advance</td>
                                                            <td className="text-end">
                                                                {(payrollDetails?.Advance__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Advance__c)}
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: "dotted 2px", color: "rgb(131 119 119)" }}>
                                                            <td >Other Deduction</td>
                                                            <td className="text-end">
                                                                {(payrollDetails?.Other_Deduction__c) == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Other_Deduction__c)}
                                                            </td>
                                                        </tr>
                                                        <tr style={{ borderBottom: "1px solid white", color: "rgb(131 119 119)", visibility: "hidden" }} >
                                                            <td >Dummy</td>
                                                            {/* <td className="text-end">Dummy</td> */}
                                                        </tr>                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="card-footer d-flex justify-content-between bg-white mt-0 pt-3 pl-4 pr-4"
                                            style={{ borderTop: "1px solid #c9bcbc", paddingBottom: "14px", fontFamily: "Montserrat sansSerif", color: "dimgrey" }}>
                                            <Col lg={9} sm={7} xs={7}>
                                                <span className='mx-3 fw-semibold'>Total Deduction</span>
                                            </Col>
                                            <Col lg={3} sm={5} xs={5}>
                                                <h6 className='mx-3 text-end fw-semibold' style={{ color: "#4f8b78" }}>
                                                    {payrollDetails?.Total_Deduction__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails?.Total_Deduction__c)}
                                                </h6>
                                            </Col>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>

                        </Container>

                        <Container className='pb-5 mt-3'>
                            <Row className='mx-3 p-2 row text-center' style={{ backgroundColor: "white", borderRadius: "5px" }}>
                                <Col lg={12} sm={12} xs={12}>
                                    <span className='mx-3' style={{ color: "#4f8b78", fontSize: "18px", fontWeight: "700" }}>
                                        Net Payable Income:
                                    </span>
                                    <span style={{ color: "#4f8b78", fontSize: "18px" }}>
                                        {payrollDetails.Net_Payable_Amount__c == null ? 'Rs. 0.00' : formatNumberWithDecimals(payrollDetails.Net_Payable_Amount__c)} ({payrollDetails.Net_Payable_Amount__c == null ? 'Zero' : numberToEnglishWord(payrollDetails.Net_Payable_Amount__c)} Rupees)
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </>
                    : <Shimmer />
            }
        </>
    ) : <Shimmer />
}

export default PayrollView