import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Heading = ({headingName}) => {
    return (
        <Container className='mt-3'>
            <Row className='text-center d-flex justify-content-center'>
                <Col lg={10} xs={12} sm={12}>
                    <div className='w-100 text-center p-2' style={{ height: '40px', backgroundColor: 'var(--primary-color)', borderRadius: '5px' }} >
                        <span className='fw-semibold' style={{ color: 'var(--highlight-color)', fontSize: 'large' }}>
                            {headingName}
                        </span>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Heading;