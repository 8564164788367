// React Utilities
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
// React Bootstrap
import { Card } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import jwt_decode from "jwt-decode";
// Custom API's 
import JajotApi from '../../api/JajotApi';
// Custom Components
import UpdateImage from '../modal/UpdateImage';

const Sidebar = () => {
  const [userInfo, setUserInfo] = useState();
  const [userPofile, setUserProfile] = useState();
  const [enableModal, setEnableModal] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let user = await JajotApi.getContactRecordByEmail();
      let userProfile = await JajotApi.getEmployeeImage();
      if (userProfile?.success) {
        setUserProfile(userProfile?.record);
      } else {
        setUserProfile(user?.profile);
      }
      if (user?.success) {
        setUserInfo(user?.records)
      }
    })();
    setUserInfo(jwt_decode(localStorage.getItem('token')));
  }, [changeImage, enableModal]);

  const navigatePage = (destination, defaultHide) => {
    if (isMobile || defaultHide)
      hideSideBar();
    setTimeout(() => {
      navigate(destination);
    }, 100)
  }
  const hideSideBar = () => {
    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");
  }

  return userInfo !== undefined && (
    <>
      <nav id="sidebar" className={isMobile ? 'hide' : 'show'} >
        <div className='d-flex justify-content-center align-items-center'>
          {
            userPofile?.url !== undefined ?
              <Card style={{ width: "100%" }}>
                <Card.Title className='text-end m-1 cursor-pointer'>
                  <i className="fa-solid fa-pencil rounded-1 border p-2 fs-6" onClick={() => setEnableModal(!enableModal)}></i>
                </Card.Title>
                <Card.Body>
                  <div className='image-container d-flex justify-content-center' >
                    <Card.Img src={userPofile?.url} varient="top" className='img-container rounded-circle img-fluid p-1' style={{ border: "var(--primary-color)", height: '145px', width: "140px" }} />
                  </div>
                  <p className='text-center mt-2'>
                    <span className='heading'>{userInfo?.Name ? userInfo?.Name : "Employee Name"}</span>   <br />
                    <span style={{ fontSize: "15px" }}>{userInfo?.Designation__c ? userInfo?.Designation__c : "Employee Designation"}</span>
                  </p>
                </Card.Body>
              </Card>
              : <Card style={{ width: "100%" }}>
                <Card.Title className='text-end m-1 cursor-pointer'>
                  <i className="fa-solid fa-pencil rounded-1 border p-2 fs-6" onClick={() => setEnableModal(!enableModal)}></i>
                </Card.Title>
                <Card.Body>
                  <div className='image-container d-flex justify-content-center' >
                    <Card.Img src="/default_emp_profile.png" varient="top" className='img-container rounded-circle img-fluid w-75 p-1' style={{ border: "var(--primary-color)", height: '170px', width: "170px" }} />
                  </div>
                  <p className='text-center mt-2'>
                    <span className='heading'>{userInfo?.Name ? userInfo?.Name : "Employee Name"}</span>   <br />
                    <span style={{ fontSize: "15px" }}>{userInfo?.Designation__c ? userInfo?.Designation__c : "Employee Designation"}</span>
                  </p>
                </Card.Body>
              </Card>
          }
        </div>
        <div>
          <ul className="list-unstyled components menu-list">
            <li className={`${location.pathname === '/' ? 'active' : ''}`}>
              <span className="custom-link-sidebar heading" onClick={(e) => navigatePage("/", false)} ><i className="fa-solid fa-house mx-2" ></i> Home</span>
            </li>

            <li className={`${location.pathname === '/attendance' ? 'active' : ''}`}>
              <span className="custom-link-sidebar heading" onClick={(e) => navigatePage("/attendance", false)} ><i className="fa-solid fa-book mx-2"></i> Attendance</span>
            </li>

            <li className={`${location.pathname.includes('payroll') ? 'active' : ''}`}>
              <span className="custom-link-sidebar heading" onClick={(e) => navigatePage("payroll", false)}><i className="fa-solid fa-receipt mx-2"></i> Pay Slips</span>
            </li>

            <li className={`${location.pathname.includes('target') ? 'active' : ''}`}>
              <span className="custom-link-sidebar heading" onClick={(e) => navigatePage("target", false)}> <i className="fa-solid fa-bullseye mx-2"></i> Target</span>
            </li>

            <li className={`${location.pathname.includes('leave') ? 'active' : ''}`}>
              <span className="custom-link-sidebar heading" onClick={(e) => navigatePage("leave", false)}> <i className="fa-solid fa-person-walking-luggage mx-2"></i> Leaves</span>
            </li>

            <li className={`${location.pathname.includes('changepassword') ? 'active' : ''}`}>
              <span className="custom-link-sidebar heading" onClick={(e) => navigatePage("changepassword", false)}> <i className="fa-solid fa-key mx-2"></i> Change Password</span>
            </li>

            <div className="sidebar-header text-dark" style={{ padding: "1rem 2rem 2rem 1rem", borderTop: "1px solid #15a362", textAlign: "center" }}>
              <img src="/ibirds_logo.png" style={{ width: "50%" }} alt="indicrm.logo" />
              <p style={{ paddingTop: ".5rem" }}>Powered by <a href="https://indicrm.io" target='__blank' style={{ color: "#15a362" }}>indiCRM.io</a></p>
            </div>

          </ul>

        </div>
      </nav>

      <UpdateImage show={enableModal} changeImage={changeImage} setChangeImage={setChangeImage} previousImage={userPofile?.url} onHide={setEnableModal} />
    </>
  )
}

export default Sidebar
