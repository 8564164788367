/** @format */

import { Outlet, useNavigate } from "react-router-dom";
import { React, useEffect } from "react";

import Header from "./Header";
import Sidebar from "./Sidebar"
import { Toaster } from 'react-hot-toast';
import Footer from "./Footer";
const Main = ({ setIsLogout, editEmployee }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.removeItem('token');
      navigate("/")
    }
  }, []);

  return (
    <>
      <Toaster />
      <div className="wrapper">
        <Sidebar editEmployee={editEmployee} />
        <div id="content">
          <Header setIsLogout={setIsLogout} />
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Main;
