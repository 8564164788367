import { Card, Col, Container, Form, Row, Button } from "react-bootstrap"
import { MultiSelect } from "react-multi-select-component";
import { ShimmerTable } from "react-shimmer-effects";

import { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';

import JajotApi from '../../api/JajotApi';
import LeaveSelection from "./LeaveSelection";
import Shimmer from "../common/Shimmer";
import LeaveStatusButton from "./LeaveStatusButton";
import moment from "moment";
import LeaveDataTable from "./LeaveDataTable";
import LeaveDashBoard from "./LeaveDashBoard";
import Heading from "../common/Heading";
import Confirm from "../modal/Confirm";
import jwtDecode from "jwt-decode";

const Leave = () => {
    const [revokedLeave, setRevokeLeave] = useState();
    const [visibleModal, setVisibleModal] = useState();
    const [isSpinner, setIsSpiner] = useState();
    const [notifyMembers, setNotifyMembers] = useState();
    const [dashBoardDetail, setDashboardDetail] = useState();
    const [leaveDataTableDetail, setLeaveDataTableDetail] = useState();
    const [holidayActive, setHoliDayActive] = useState(false);
    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);

    const dateFormate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Table Header Defination
    const header = [
        { title: 'Date', prop: 'leaveDate', isFilterable: true, isSortable: true, cell: (row) => { return (moment(row.leaveDate).format("DD-MM-YYYY")) } },
        { title: 'Reason', prop: 'reason', isFilterable: true, isSortable: true, defaultFilterBy: "reason" },
        { title: 'Leave Type', prop: 'leaveType', isFilterable: true, isSortable: true },
        { title: 'Status', prop: 'status', isFilterable: true, isSortable: true, cell: (row) => { return (<LeaveStatusButton status={row.status} />) } },
        { title: 'Action', prop: 'action', isFilterable: true, cell: (row) => { return (((row.status.toLowerCase() === 'pending for approval' || row.status.toLowerCase() === 'approved') && (moment(row.leaveDate) > moment(new Date()))) && <button className="btn btn-sm rounded-pill border-0 text-dark" onClick={() => { setVisibleModal(!visibleModal); setRevokeLeave(row); }} style={{ backgroundColor: "lightgray" }}>Revoke</button>) } },
    ]

    const [leaveCollection, setLeaveCollection] = useState([]);
    const [formDetails, setFormDetails] = useState({});
    const [selectedusers, setselectedusers] = useState([]);
    const [tempSelectionUser, setTempSelectionUser] = useState();

    const leaveRevoked = async () => {
        setVisibleModal(false);
        setIsSpiner(false);
        let user = jwtDecode(localStorage.getItem('token'))
        let result = await JajotApi.changeLeaveStatusToRevoke(revokedLeave, user?.contactDetails?.Id);
        if (result.success) {
            setIsSpiner(true);
            toast.success(result.message);
        }
        fetchLeaveTableRecords();
    }

    const handleSubmit = async () => {
        if (!formDetails.startDate && !formDetails.endDate && !formDetails.reason) {
            toast.error("Start date, end date, and reason must be selected");
            return;
        }
        if (new Date(formDetails.endDate).getDate() < new Date(formDetails.startDate).getDate()) {
            toast.error("End date must be greater then start date");
            return
        }
        if (!formDetails.endDate) {
            toast.error("End date must be selected");
            return
        }
        if (!formDetails.startDate) {
            toast.error("Start date must be selected");
            return
        }
        if (!formDetails.reason) {
            toast.error("Reason must be selected");
            return
        }
        console.log(selectedusers);
        // All Succes condition 
        if (formDetails.startDate !== undefined && formDetails.endDate !== undefined && formDetails.reason !== undefined) {            
            // setselectedusers([]);
            setHoliDayActive(true);
            let result = await JajotApi.getAllLeavesRecords(formDetails);
            if (result?.success) {
                setLeaveCollection(result?.records?.leaveDetails);
            } else {
                toast.error("Error in getting leave records");
            }
            formDetails.notifyMembers = tempSelectionUser;
        }
    }

    function customFilter(options, filter) {
        let filteredOptions = options.filter(option => {
            const labelParts = option.value.split(',');
            const label = labelParts[labelParts.length - 1];
            let labelsplit = label.split('-')[0];
            return labelsplit.toLowerCase().includes(filter.toLowerCase());
        });
        return filteredOptions;
    }

    const removeBadge = (indexToRemove) => {
        setselectedusers(selectedusers.filter((_, index) => index !== indexToRemove));
    };

    const handleUserSelection = (selected) => {
        console.log(selected);
        if (selected.length <= 10) {
            const selectedLabel = selected.map(item => ({ label: item.value, value: item.value }));
            setTempSelectionUser(selectedLabel)
            const selectedLabels = selected.map(item => ({ label: item.value.split('-')[0].trim(), value: item.value }));
            setselectedusers(selectedLabels);            
        } else {
            const selectedLabels = selected.slice(0, 10).map(item => ({ label: item.value, value: item.value }));
            setselectedusers(selectedLabels);
            toast.error("Maximum 10 Members can be selected");
        }
    };

    const fetchLeaveTableRecords = async () => {
        setIsSpiner(false);
        let result = await JajotApi.getLeavesTableData();
        if (result?.success) {
            setIsSpiner(true);
            setLeaveDataTableDetail(result?.records?.leaveDetails.filter((item) => (
                (item.status === null) && (item.status = "Pending for approval")
            )))
            setLeaveDataTableDetail(result?.records?.leaveDetails)
        }
        return result?.records?.leaveDetails;
    }

    const fetchNotifyUsers = async () => {
        let result = await JajotApi.getAllUsers();
        if (result?.success) {
            const updatedUsersList = result.record.map((value) => ({
                label: value.empName,
                email: value.empEmail,
                profileURL: value.profileURL
            }));
            setNotifyMembers(updatedUsersList);
        }
    }

    useEffect(() => {
        (async () => {
            let result = await JajotApi.getLeavesDashboardRecord();
            if (result?.success) {
                setDashboardDetail(result.record)
            }
        })();
        fetchNotifyUsers();
        fetchLeaveTableRecords();
    }, [formDetails]);

    return (
        // Leave Section                   
        <>
            {/* Leave Heading */}
            <Heading headingName={"Leave"} />


            {/* Crousel design */}
            {
                notifyMembers !== undefined ?
                    <>
                        <LeaveDashBoard dashBoardDetail={dashBoardDetail} />

                        {/* Leave From Submission */}
                        <Container className='mb-2 mt-4'>
                            <Row>
                                <Col lg={1}></Col>
                                <Col lg={4} sm={12} xs={12} className="mb-3">
                                    <Card className='h-100' style={{ borderTop: "3px solid #4f6479" }}>
                                        <Card.Body>
                                            <Row>
                                                <Col lg={6} sm={12} xs={12}>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label className="form-view-label" htmlFor="formBasicFirstName" >
                                                            Start Date
                                                        </Form.Label>
                                                        <Form.Control type="date" value={formDetails.startDate || ''} required min={dateFormate(firstDayOfCurrentMonth)} max={dateFormate(lastDayOfNextMonth)} defaultValue={formDetails.startDate} onChange={(e) => setFormDetails((prev) => ({ ...prev, startDate: e.target.value }))} />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6} sm={12} xs={12}>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                            End Date
                                                        </Form.Label>
                                                        <Form.Control type="date" value={formDetails.endDate || ''} required min={dateFormate(firstDayOfCurrentMonth)} max={dateFormate(lastDayOfNextMonth)} defaultValue={formDetails.endDate} onChange={(e) => setFormDetails((prev) => ({ ...prev, endDate: e.target.value }))} />
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={6} sm={12} xs={12}>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                            Reason
                                                        </Form.Label>
                                                        <Form.Select required value={formDetails.reason || ''} placeholder="Select Reason" aria-label="Default select example" name="reason" onChange={(e) => setFormDetails((prev) => ({ ...prev, reason: e.target.value }))} >
                                                            <option value="">-- None --</option>
                                                            <option value="Death">Death</option>
                                                            <option value="Exam">Exam</option>
                                                            <option value="Festival">Festival</option>
                                                            <option value="Function">Function</option>
                                                            <option value="Marriage">Marriage</option>
                                                            <option value="Maternity">Maternity</option>
                                                            <option value="Medical">Medical</option>
                                                            <option value="Out of Station">Out of Station</option>
                                                            <option value="Paternity">Paternity</option>
                                                            <option value="Vacation">Vacation</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={12} sm={12} xs={12}>
                                                    <Form.Group>
                                                        <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                            Comment
                                                        </Form.Label>
                                                        <Form.Control as="textarea" name="comment" defaultValue={formDetails.comment || ''} onChange={(e) => setFormDetails((prev) => ({ ...prev, comment: e.target.value }))} placeholder='Enter comment...' rows={2} maxLength={255} style={{ overflow: 'hidden', textOverflow: 'ellipsis', resize: 'none' }} />
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={12} sm={12} xs={12} className='my-3'>
                                                    <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                        Notify Team Members
                                                    </Form.Label>

                                                    <MultiSelect
                                                        value={selectedusers || ''}
                                                        options={notifyMembers.map((item, index) => ({
                                                            label:
                                                                (
                                                                    <div key={item.email}>
                                                                        <img alt="User" className="member-pic mx-3" src={item.profileURL ? item.profileURL : "default_emp_profile.png"} style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                                                                        <span>{item.label}</span>
                                                                    </div>
                                                                ),
                                                            value: item.label + '-' + item.email,
                                                        }))}
                                                        onChange={(selected) => handleUserSelection(selected)}
                                                        labelledBy="documentType-label"
                                                        hasSelectAll={false}
                                                        filterOptions={customFilter}
                                                    />
                                                </Col>

                                                <>
                                                    {selectedusers.map((value, index) => (
                                                        <Card key={index} className="d-inline-flex justify-content-start mx-1 my-1" style={{ width: `${value.label.length * 11}px`, height: '25px', backgroundColor: '#e8e9ed' }}>
                                                            <Card.Body className="d-flex align-items-center" style={{ fontSize: '11px', fontWeight: 'lighter', padding: '0px', position: 'relative' }}>
                                                                {value.label}
                                                                <Button variant="transparent" size='sm' className="btn-close" onClick={() => removeBadge(index)} style={{ color: '#A2A2A2', padding: '2px', position: 'absolute', right: '0', width: '7px', height: '6px' }}>
                                                                    <i className="bi bi-x"></i>
                                                                </Button>
                                                            </Card.Body>
                                                        </Card>
                                                    ))}
                                                </>
                                            </Row>
                                        </Card.Body>

                                        <Card.Footer style={{ backgroundColor: "white" }} >
                                            <Row className='mt-2'>
                                                <Col lg={12} sm={12} xs={12} className="justify-content-end">
                                                    <button className='btn btn-primary float-end mb-2' onClick={handleSubmit}>
                                                        Next
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    </Card>
                                </Col>

                                {/* Leaves section half or full day section with the shimmertable according to condition  */}
                                {
                                    holidayActive ?
                                        (formDetails?.startDate !== undefined && formDetails?.endDate !== undefined && formDetails?.reason !== undefined) &&
                                        <LeaveSelection setselectedusers={setselectedusers} setHoliDayActive={setHoliDayActive} fetchLeaveTableRecords={fetchLeaveTableRecords} leaveCollection={leaveCollection} notifyMembers={selectedusers} setLeaveCollection={setLeaveCollection} formDetails={formDetails} setFormDetails={setFormDetails} />
                                        : (formDetails?.startDate !== undefined || formDetails?.endDate !== undefined || formDetails?.reason !== undefined) &&
                                        <Col lg={6} className="mt-5" >
                                            <ShimmerTable row={1} col={3} />
                                            <p className='text-center'>Click "Next" to Proceed</p>
                                            <div className="custom-shimmer-class mt-4">
                                                <ShimmerTable row={1} col={3} />
                                            </div>
                                        </Col>
                                }
                            </Row>
                        </Container>
                        {/* Data table for leaves */}
                        {
                            leaveDataTableDetail !== undefined &&
                                isSpinner === true ?
                                <LeaveDataTable header={header} body={leaveDataTableDetail} fetchLeaveTableRecords={fetchLeaveTableRecords} setBody={setLeaveDataTableDetail} />
                                : <Shimmer />
                        }
                    </>
                    : <Shimmer />
            }

            <Confirm visibleModal={visibleModal} leaveRevoked={leaveRevoked} setVisibleModal={setVisibleModal} table={"revoke"} title={"Revoke Leave"} message={"Do you want to revoke leave"} />

            <Toaster />
        </>
    )
}

export default Leave