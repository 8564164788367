import React from 'react'
import { Col, Row } from "react-bootstrap";
const Footer = () => {
    return (
        <footer className="p-2 text-white" style={{ background: "#4f8b78", position: "fixed", zIndex: '999' }}>
            <Row className="g-0">
                <Col lg={10} className=" text-center">
                    ©Copyright<a className="text-white" href="https://jajot.in/" target='__blank'>
                        <b className='mx-2'>Jajot Enterprises Pvt. Ltd.</b> All Rights Reserved.
                    </a>
                </Col>
            </Row>
        </footer>
    )
}

export default Footer